import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Cancelar facturas
            </h2>
            <p>
                Hay dos formas de cancelar facturas, desde el detalle del pedido o desde la <b>sección de Mis Facturas</b>.                
            </p>
            <ImageFooted explanation={`Puedes cancelar una factura desde la vista de detalles del pedido. Pedidos cancelados que solo cuentan con un solo producto se deben cancelar en lugar de emitir nota de crédito, ya que su resultado es el mismo pero las cancelaciones son gratuitas.`}>
                <IMG_Mercadolibre file={`cancelarFact`} />
            </ImageFooted>
            <ImageFooted explanation={`Desde la vista de "Mis Facturas" puedes cancelar cualquier tipo de factura dando click en el ícono de basura.`}>
                <IMG_Mercadolibre file={`misfacturas`} />
            </ImageFooted>
            <p>
                En esta última sección, podras descargar los PDFs y XMLs de las facturas que necesites revisar. <br />
                <b>La opción de crear reporte</b> te llevará a la vista de reporte detallado de tu facturación.
            </p>
            <SectionNavigate next={`/ecommerce/mercadolibre/reporte-facturas`} previous={`/ecommerce/mercadolibre/productos-multiple`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;